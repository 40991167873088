$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #1C2932;
$color-burlywood: #DAB68A;
$color-wine: #752D2E;
$color-davy-grey: #535A4F;
$color-off-black: #101010;

$color-deepblue: #1C2932;
$color-smokey-grey: #707070;
$color-rust: #A65D44;
$color-eggshell: #F2E6DB;
$color-oil: #1D121B;

$primary-font: "hwt-slab-columbian", serif;
$copyright-font: Helvetica Neue, Helvetica, Arial, sans-serif;
