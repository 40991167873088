@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-white;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;

	// FIXEDBG
	// background-image: url('../img/music-bg-bw.jpg');
	// background-position: center;
	// background-size: cover;
	// background-repeat: no-repeat;
	overflow-y: scroll;
	height: 100vh;
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-white;
	}
}

h2 {
	text-transform: uppercase;
	letter-spacing: rems(6);
}

.social {
	display: flex;
	align-items: center;
	list-style: none;
	gap: rems(20);
	padding: 0;
	margin: 0;
	font-size: rems(33);

	li {
		a {
			transition: all .3s ease-in-out;
			font-size: rems(24);

			@include hover-focus {
				transform: scale(1.2);
			}

			i {}
		}
	}
}

header {
	background-color: $color-white;

	.nav-wrapper {
		width: 100%;
		padding: rems(40) 0 rems(34);

		.container {
			position: relative;

			@media screen and (max-width: 1299px) {
				max-width: $container-max-width;
			}

			nav#nav-menu {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: rems(10) 0;

				@include tablet-down {
					flex-direction: column;
				}


				.nav-group {
					display: flex;
					align-items: center;
					gap: rems(65);

					@include tablet-down {
						flex-direction: column;
						justify-content: center;
					}

					#logo {
						filter: invert(1);
					}

					ul.nav-list {
						list-style: none;
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: rems(72);
						padding: 0;
						margin: 0;

						@include tablet-down {
							flex-direction: column;
							margin-bottom: rems(60);

							&:not(.active) {
								display: none;
							}
						}

						li.nav-item {
							a {
								text-transform: uppercase;
								font-size: rems(24);
								letter-spacing: 2px;
								color: $color-off-black;
							}
						}
					}
				}

				#nav-social {
					color: $color-off-black;

					@include tablet-down {
						&:not(.active) {
							display: none;
						}
					}
				}
			}

			.mobile-menu-wrapper {
				position: absolute;
				top: rems(10);
				right: rems(30);
				z-index: 20;

				.mobile-menu-icon {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					pointer-events: all;
					cursor: pointer;
					position: relative;
					z-index: 30;

					// ICON SIZE
					width: 35px;
					height: 40px;

					@include desktop-sm-up {
						display: none;
					}
				}

				.line {
					width: 100%;
					height: 2px;
					background-color: $color-off-black;
					margin: 4.5px;
					transition: transform 0.3s ease;
					transform-origin: center;


					&.active {
						transform: rotate(45deg) !important;
						position: absolute;
						top: 30%;

						&.active:nth-child(2) {
							display: none;
						}

						&.active:nth-child(3) {
							transform: rotate(-45deg) !important;
						}
					}
				}
			}
		}
	}

	section#hero {
		position: relative;

		img {
			width: 100%;
			height: auto;

			@include tablet-down {
				&.desktop {
					display: none;
				}
			}

			@include desktop-sm-up {
				&.mobile {
					display: none;
				}
			}
		}

		a.btn {
			position: absolute;
			right: rems(80);
			top: rems(20);
			background-color: $color-white;
			color: $color-off-black;
			padding: rems(16) rems(20);
			font-size: rems(37);
			text-transform: uppercase;
			margin: 0 auto;

			@include tablet-down {
				padding: rems(13) rems(16);
				font-size: rems(30);
				right: rems(40);
			}

			@include phone-down {
				padding: rems(10) rems(16);
				font-size: rems(20);
				right: rems(20);
			}

			@include hover-focus {
				opacity: .6;
			}
		}
	}
}

main {

	section#tour {
		padding: rems(68) 0 rems(100);
		background-image: url('../img/tour-bg-240530.jpg');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;


		@include phone-down {
			background-position: center right 30%;
		}

		h2 {
			text-align: center;
			color: $color-white;
			font-size: rems(60);
			margin-top: 0;
		}

		.container {

			.scroll-container {
				width: 55%;
				max-height: rems(600);
				overflow-y: scroll;
				min-height: rems(500);

				@include phone-down {
					width: 100%;
				}

				#tour-dates {
					width: 100%;
					color: $color-eggshell;
					letter-spacing: 1.5px;

					.event-group {
						display: flex;
						gap: rems(16);
						margin: rems(33) 0;

						@include tablet-down {
							flex-direction: column;
							text-align: center;
						}

						.event-date {
							flex: 1;
						}

						.event-venue-location {
							flex: 2;
						}

						.event-links {
							flex: 1;
							display: flex;
							align-items: flex-start;
							justify-content: center;

							a.btn {
								background-color: $color-off-black;
								color: $color-white;
								padding: rems(5) rems(12);
								width: rems(130);
								text-align: center;

								@include hover-focus {
									opacity: .6;
								}
							}
						}
					}
				}
			}
		}
	}

	section#music {
		padding: rems(60) 0;

		.container {
			padding: 0 rems(60);

			h2 {}

			#music-carousel {
				position: relative;

				// Item
				.item {
					display: flex;

					@include tablet-down {
						flex-direction: column;
					}

					.cover {
						flex: 1;

						img {
							max-width: 400px;
							margin: 0 auto;

							@include tablet-down {
								padding: rems(10) rems(10) rems(40) rems(10);
							}
						}
					}

					.info {
						flex: 2;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;


						p.release {
							font-size: rems(38);
							color: $color-off-black;
							text-transform: uppercase;

							@include phone-down {
								font-size: rems(26);
							}
						}

						h2.title {
							color: $color-off-black;
							font-size: rems(60);
							margin-bottom: rems(40);
							text-align: center;

							@include tablet-down {
								font-size: rems(40);
							}

							@include phone-down {
								font-size: rems(30);
							}
						}

						a.btn {
							padding: rems(20) rems(40);
							font-size: rems(46);
							text-transform: uppercase;
							background-color: $color-off-black;
							color: $color-white;

							@include hover-focus {
								opacity: .6;
							}

							@include phone-down {
								font-size: rems(26);
							}
						}
					}
				}

				.owl-stage-outer {}

				.owl-nav {

					// Nav Buttons
					button {
						color: $color-off-black;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);

						&.owl-prev {
							left: rems(-70);

							@include tablet-down {
								left: rems(-40);
							}
						}

						&.owl-next {
							right: rems(-70);

							@include tablet-down {
								right: rems(-40);
							}
						}

						.arrow {
							i {
								font-size: rems(70);

								@include hover-focus {
									transform: scale(1.1);
								}
							}
						}
					}
				}
			}
		}
	}

	section#video {
		// background-image: url('../img/videos-bg.png');
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: center;
		padding: rems(100) 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2 {
			text-align: center;
			color: $color-off-black;
			font-size: rems(60);
			margin-top: 0;
		}

		#video-carousel {
			position: relative;
			margin-bottom: rems(70);

			// Item
			.item {
				.embed-container {
					iframe {}
				}
			}

			.owl-stage-outer {}

			.owl-nav {

				// Nav Buttons
				button {
					color: $color-white;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: rems(15);
					}

					&.owl-next {
						right: rems(15);
					}

					.arrow {
						i {
							font-size: rems(43);

							@include hover-focus {
								transform: scale(1.1);
							}
						}
					}
				}
			}
		}

		a.btn {
			background-color: $color-off-black;
			color: $color-white;
			padding: rems(16) rems(20);
			font-size: rems(37);
			text-transform: uppercase;
			margin: 0 auto;

			@include hover-focus {
				opacity: .6;
			}
		}
	}
}

footer {
	font-family: $copyright-font;
	text-align: center;
	background-color: $color-white;
	padding: rems(54) 0;
	color: $color-off-black;
	font-size: rems(18);
	font-weight: 300;
	text-transform: uppercase;

	@include phone-down {
		padding: rems(52) 0 rems(100);
	}
}